<template>
  <section class="page-contact">
    <van-skeleton
      v-if="loading"
      class="basic-skeleton"
      :row="3"
    />
    <van-tabs
      v-else
      v-model="activeTabIndex"
    >
      <van-tab title="业委会">
        <div
          v-if="document.committee"
          class="px-10 pt-5 leading-loose"
          :inner-html.prop="document.committee | markdown | htmlXss"
        />
        <van-empty
          v-else
          description="暂无内容"
        />
      </van-tab>
      <van-tab title="监事会">
        <div
          v-if="document.supervisory"
          class="px-10 pt-5 leading-loose"
          :inner-html.prop="document.supervisory | markdown | htmlXss"
        />
        <van-empty
          v-else
          description="暂无内容"
        />
      </van-tab>
      <van-tab title="居委会">
        <div
          v-if="document.resident"
          class="px-10 pt-5 leading-loose"
          :inner-html.prop="document.resident | markdown | htmlXss"
        />
        <van-empty
          v-else
          description="暂无内容"
        />
      </van-tab>
      <van-tab title="物业">
        <div
          v-if="document.housekeeper"
          class="px-10 pt-5 leading-loose"
          :inner-html.prop="document.housekeeper | markdown | htmlXss"
        />
        <van-empty
          v-else
          description="暂无内容"
        />
      </van-tab>
      <!-- <van-tab title="其他">
        <div
          v-if="document.other"
          class="px-10 pt-5 leading-loose"
          :inner-html.prop="document.other | markdown | htmlXss"
        />
        <van-empty
          v-else
          description="暂无内容"
        />
      </van-tab> -->
    </van-tabs>
  </section>
</template>

<script>
import share from '@/mixins/share'
import {mapState} from 'vuex'
export default {
  name: 'PublicContact',
  mixins: [share],
  data() {
    return {
      activeTabIndex: 0,
      document: null,
      loading: true
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['db'])
  },
  async mounted() {
    await Promise.all([this.fetch(), this.prepareWx()])
    let options = {
      title: '联系方式',
      desc: '北京阳光丽景小区日常联系方式',
    }
    this.updateShareData(options)
  },
  methods: {
    async fetch() {
      try {
        let docs = await this.db.collection('contacts').get()
        this.document = docs?.data?.[0] ?? {}
        // eslint-disable-next-line no-console
        console.log(this.document)

      } catch (err) {
        this.$toast.fail('无法获取数据')
        // eslint-disable-next-line no-console
        console.error(err)
      }
      this.loading = false
    }
  }
}
</script>